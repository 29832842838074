import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Facebook, Twitter, Pinterest } from 'react-sharingbuttons'
const url = window.location.href;
    const shareText = 'Check this site!'
const ArticlePageContent = ({ articles }) => (

                            <>
                            
                            {articles.Business.map((article, key) => (
                                <>
                                <MetaTags>
                                <title>{article.title}</title>
                                <meta name="description" content="Some description." />
                                <meta property="og:title" content="MyApp" />
                                <meta property="og:image" content="path/to/image.jpg" />
                              </MetaTags>
                                <div class="col-lg-8 col-md-12 mb-30">
                          <div class="news-details-layout1">
                                <h2 class="title-semibold-dark size-c30">{article.title}</h2>
                                <ul class="post-info-dark mb-30">                                    
                                    <li>
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }
                                    </li>                                    
                                </ul>
                                <p>{ReactHtmlParser(article.content)}</p>
                                <Facebook url={url} />
                                <Twitter url={url} shareText={shareText} />
                                <Pinterest url={url} shareText={shareText} />
                                </div>
                      </div>
                      </>
                            ))}
                            
                            </>     


);

export default ArticlePageContent;