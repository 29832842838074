import React from 'react';
import { Link } from 'react-router-dom';

const ArticlesList = ({ articles }) => (
    <>
                            
                            {articles.Category.map((article, key) => (
                                <div class="col-sm-6 col-12">
                                <div class="position-relative mb-30">
                                    <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                        <img src={article.image} alt="news" class="img-fluid width-100 mb-15" />
                                    </Link>
                                    {/*<div class="topic-box-top-xs">
                                        <div class="topic-box-sm color-cod-gray mb-20"></div>
                            </div>*/}
                                    <div class="post-date-dark">
                                        <ul>
                                            <li>
                                                <span>
                                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }</li>
                                        </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-lg mb-none">
                                    <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                    {article.title}
                                    </Link>
                                    </h3>
                                    <p>{article.description}</p>
                                </div>
                            </div>
                                
                                
                            ))}
                            
                                   

    </>
);

export default ArticlesList;