import React from 'react';
import { Link } from 'react-router-dom';

const ArticlesListHome = ({ articles }) => (
    <>
    <section class="bg-body section-space-default">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">

                    <div class="mb-20-r ne-isotope">
                        <div class="topic-border color-azure-radiance mb-30">
                            <div class="topic-box-lg color-azure-radiance">Business</div>
                        </div>
                        <div class="featuredContainer">
                            <div class="row apple">
                            <div class="col-lg-12 col-md-6 col-sm-9">
                                            <div class="row">
                            {articles.Business.map((article, key) => (                               
                               <div class="col-lg-12 col-md-6 col-sm-12">
                                <div class="media mb-30">
                                
                                {/*<Link className="img-opacity-hover" key={key} to={`/pressreleases/${article.path}/apj${article.id}/${article.alias}`}> */}
                                <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                    <img src={article.image} alt="news" class="img-fluid" style={{width:"94px"}} />
                                    </Link>
                                <div class="media-body">
                                    <div class="post-date-dark">
                                        <ul>
                                            <li>
                                                <span>
                                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }                                                
                                                </li>
                                        </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-md mb-none">
                                    <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                        {article.title}
                                    </Link>    
                                    </h3>
                                    <p>{article.description}</p>
                                </div>
                            </div>
                            </div>
                                
                            ))}
                        </div>
                        </div>
                        </div>
                        </div>

                        </div>
                
                        <div class="mb-20-r ne-isotope">
                        <div class="topic-border color-azure-radiance mb-30">
                            <div class="topic-box-lg color-azure-radiance">Technology</div>
                        </div>
                        <div class="featuredContainer">
                            <div class="row apple">
                            <div class="col-lg-12 col-md-6 col-sm-9">
                                            <div class="row">
                            {articles.Technology.map((article, key) => (                               
                               <div class="col-lg-12 col-md-6 col-sm-12">
                                <div class="media mb-30">
                                
                                <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                    <img src={article.image} alt="news" class="img-fluid" style={{width:"94px"}} />
                                    </Link>
                                <div class="media-body">
                                    <div class="post-date-dark">
                                        <ul>
                                            <li>
                                                <span>
                                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }</li>
                                        </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-md mb-none">
                                        <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                            {article.title}
                                        </Link>    
                                    </h3>
                                    <p>{article.description}</p>
                                </div>
                            </div>
                            </div>
                                
                            ))}
                        </div>
                        </div>
                        </div>
                        </div>

                        </div>

                        <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="topic-border color-apple mb-30 width-100">
                                        <div class="topic-box-lg color-apple">Education</div>
                                    </div>
                                    {articles.Education.map((article, key) => (
                                    <div class="media mb-30">                                        
                                        <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                            <img src={article.image} alt="news" class="img-fluid" style={{width:"94px"}} />
                                        </Link>
                                        <div class="media-body">
                                            <div class="post-date-dark">
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                        </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }</li>
                                                </ul>
                                            </div>
                                            <h3 class="title-medium-dark size-md mb-none">
                                                <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                                    {article.title}
                                                </Link>    
                                            </h3>
                                        </div>
                                    </div>
                                    ))}
                                 </div>
                        
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="topic-border color-apple mb-30 width-100">
                                        <div class="topic-box-lg color-apple">Finance</div>
                                    </div>
                                    {articles.Finance.map((article, key) => (
                                        <div class="media mb-30">                                        
                                        <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                            <img src={article.image} alt="news" class="img-fluid" style={{width:"94px"}} />
                                        </Link>
                                        <div class="media-body">
                                            <div class="post-date-dark">
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                        </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }</li>
                                                </ul>
                                            </div>
                                            <h3 class="title-medium-dark size-md mb-none">
                                                <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                                    {article.title}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    ))}
                                 </div>
                         </div> 
                         </div>
           
            <div class="ne-sidebar sidebar-break-md col-lg-4 col-md-12">
                            
                            <div class="sidebar-box">
                                <div class="ne-banner-layout1 text-center">
                                {articles.ads['sidebar']}
                                </div>
                            </div>
                            <div class="sidebar-box">
                                <div class="topic-border color-cod-gray mb-5">
                                    <div class="topic-box-lg color-cod-gray">Recent News</div>
                                </div>
                                <div class="row show-item4-md">
                                {articles.latest.map((article, key) => (
                               
                                    <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                                        <div class="position-relative mt-25">
                                            <Link className="mb-10 img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                                <img src={article.image} alt="Recent News" class="img-fluid m-auto width-100" />
                                            </Link>
                                            {/*<div class="topic-box-top-xs">
                                                <div class="topic-box-sm color-cod-gray mb-20"></div>
                                </div>*/}
                                            <h3 class="title-medium-dark size-md mb-none">
                                                <Link className="img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                                    {article.title}
                                                </Link>
                                            </h3>
                                            <p>{article.description}</p>
                                        </div>
                                    </div>                                   
                                
                                ))}
                                </div>
                            </div>
                    
                        <div class="row">
                            <div class="col-12">
                                <div class="ne-banner-layout1 text-center mt-20-r">
                                   {articles.ads['sidebar']}
                                </div>
                            </div>
                        </div>
                 </div>
                 </div>
        </div>
    </section> 
    <section class="bg-body section-space-less30">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="topic-border color-hollywood-cerise mb-30">
                                <div class="topic-box-lg color-hollywood-cerise">Travel</div>
                            </div>
                            <div class="row">

                        {articles.Travel.map((article, key) => (
                            
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="mb-25">
                            
                                    <Link className="article-list-item" key={key} to={`/${article.pathnew}`}>                                                     
                            
                                    <img src={article.image} alt="news" class="img-fluid width-100 mb-15" />
                                    </Link>
                                <div class="post-date-dark">
                                    <ul>
                                        <li>
                                            <span>
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                            </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }</li>
                                    </ul>
                                </div>
                                <h3 class="title-medium-dark size-lg">
                                    <Link className="article-list-item" key={key} to={`/${article.pathnew}`}>
                                        {article.title}
                                    </Link>    
                                </h3>
                            </div>
                        </div>
                        
                        ))}
                        </div>
                        </div>
                     </div>
                 </div>
    </section>                    
          
    </>
);

export default ArticlesListHome;