import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import GoogleAd from '../components/GoogleAd';

const Sidebar = ({ articles }) => (
    <>
                        <div class="ne-sidebar sidebar-break-md col-lg-4 col-md-12">
                            
                        <div class="sidebar-box">
                                <div class="ne-banner-layout1 text-center">
                                <GoogleAd slot="7095138827" timeout={1000} classNames="page-bottom" />
                                
                                </div>
                            </div>
                            <div class="sidebar-box">
                                <div class="topic-border color-cod-gray mb-30">
                                    <div class="topic-box-lg color-cod-gray">Recent News</div>
                                </div>
                                <div class="row show-item4-md">
                                {articles.latest.map((article, key) => (
                               
                                    <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                                        <div class="position-relative mt-25">
                                            <Link className="mb-10 img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                                <img src={article.image} alt="Recent News" class="img-fluid m-auto width-100" />
                                            </Link>
                                            {/*<div class="topic-box-top-xs">
                                                <div class="topic-box-sm color-cod-gray mb-20"></div>
                                </div>*/}
                                            <div class="post-date-dark">
                                        <ul>
                                            <li>
                                                <span>
                                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </span>{new Date(article.date).toLocaleDateString('en-US', {
                                            weekday: 'short',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                            })
                                        }</li>
                                        </ul>
                                    </div>
                                            <h3 class="title-medium-dark size-md mb-none">
                                            <Link className="mb-10 img-opacity-hover" key={key} to={`/${article.pathnew}`}>
                                                {article.title}
                                            </Link>    
                                            </h3>
                                            <p>{ReactHtmlParser(article.description)}</p>
                                        </div>
                                    </div>                                   
                                
                                ))}
                                </div>
                            </div>
                    
                            <div class="sidebar-box">
                                <div class="ne-banner-layout1 text-center">
                                <GoogleAd slot="7095138827" timeout={1000} classNames="page-bottom" />
                                
                                </div>
                            </div>
                        </div>
                 
        
                                   

    </>
);

export default Sidebar;