import React from 'react';

const AboutPage = () => (
    <>
    <h1>About me</h1>
    <p>
       
    </p>
    
    </>
);

export default AboutPage;