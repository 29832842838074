import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import ArticlesList from '../components/ArticlesList';
import Sidebar from '../components/Sidebar';
import ReactPaginate from 'react-paginate';



const ArticlesListPage = ({match}) => {
    
    const name = match.params.name;  
    
    const [articles, setArticleInfo] = useState([]);


    const [pageCount, setPageCount] = useState(1); 
    const [isLoaded, setisLoaded] = useState(false);
    const [currentPage, setcurrentPage] = useState(0); 
    const [query, setQuery] = useState('startups'); 

    const URL = `https://bharatdaily.in/content/article-list-test.php?c=${name}&p=${currentPage}`;

            
  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
    handleFetch();
  };


   const handleFetch = () => {
    fetch(URL)
      .then(response => response.json())
      .then(body => {
        setArticleInfo(body.article);
            setPageCount(body.npages); 
            setisLoaded(true);
      })
      .catch(error => console.error('Error', error));
  };

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`https://bharatdaily.in/content/article-list-test.php?c=${name}&p=${currentPage}`);
            const body = await result.json();
            setArticleInfo(body.article);
            setPageCount(body.npages); 
            setisLoaded(true);
        }
        fetchData();
    }, [name,currentPage]);
    
    if (articles=="") {
      return <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
    }
    return (
    <>
    <MetaTags>
            <title>{name}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
          <section class="bg-body section-space-less30">
              <div class="container">
                  <div class="row">
                    <div class="col-lg-8 col-md-12">
                      <div class="row">
                        <ArticlesList articles={articles} />
                      </div>
                      {isLoaded ? (
                        <div class="row mt-20-r mb-30">
                          <div class="col-sm-8 col-12">
                            <div class="pagination-btn-wrapper text-center--xs mb15--xs">
                              <ReactPaginate
                                pageCount={pageCount}
                                pageRange={10}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                
                                breakClassName={'page'}
                                nextLinkClassName={'page'}
                                pageClassName={'page'}
                                disabledClassNae={'disabled'}
                                activeClassName={'active'}
                              />
                            </div>
                          </div>
                        </div>
                        ) : (
                          <>
                          </>
                        )} 
                      </div>  
                      <Sidebar articles={articles} />
        
        </div></div></section>
      
    </>
    );

};
  

export default ArticlesListPage;