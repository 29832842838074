import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
    <header>
                <div id="header-layout2" class="header-style3">
                    <div class="header-top-bar">
                        <div class="top-bar-top bg-primarytextcolor">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-8 col-md-12">
                                        {/*<ul class="news-info-list text-center--md">
                                            <li>
                                                <i class="fa fa-map-marker" aria-hidden="true"></i></li>
                                            <li>
                                                <i class="fa fa-calendar" aria-hidden="true"></i><span id="current_date"></span></li>
                                            <li>
                                                <i class="fa fa-clock-o" aria-hidden="true"></i></li>
                                            <li>
                                                <i class="fa fa-cloud" aria-hidden="true"></i></li>
</ul>*/}
                                    </div>
                                    <div class="col-lg-4 d-none d-lg-block">
                                        <ul class="header-social">
                                            <li>
                                                <a href="#" title="facebook">
                                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="twitter">
                                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="google-plus">
                                                    <i class="fa fa-google-plus" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="linkedin">
                                                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="pinterest">
                                                    <i class="fa fa-pinterest" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="rss">
                                                    <i class="fa fa-rss" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="vimeo">
                                                    <i class="fa fa-vimeo" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="top-bar-bottom bg-body pt-20 pb-20 d-none d-lg-block">
                            <div class="container">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-4">
                                        <div class="logo-area">
                                            <a href="/" class="img-fluid">
                                                <img src="/assets/img/logo-dark.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="ne-banner-layout1 pull-right">
                                        
                                            
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-menu-area bg-body border-bottom" id="sticker">
                        <div class="container">
                            <div class="bg-primarytextcolor pl-15 pr-15">
                                <div class="row no-gutters d-flex align-items-center">
                                    <div class="col-lg-10 position-static d-none d-lg-block">
                                        <div class="ne-main-menu">
                                            <nav id="dropdown">
                                                <ul>
                                                    <li>
                                                    <Link to="/">Home</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/category/business">Business</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/category/finance">Finance</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/category/technology">Technology</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/category/arts-entertainment">Arts & Entertainment</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/category/world">World</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                   
                                
                            <div class="col-lg-2 col-md-12 text-right position-static">
                                        <div class="header-action-item on-mobile-fixed">
                                            <ul>
                                                
                                                <li class="d-none d-sm-block d-md-block d-lg-none">
                                                    <button type="button" class="login-btn" data-toggle="modal" data-target="#myModal">
                                                        <i class="fa fa-user" aria-hidden="true"></i>Sign in
                                                    </button>
                                                </li>
                                                <li>
                                                    <div id="side-menu-trigger" class="offcanvas-menu-btn offcanvas-btn-repoint">
                                                        <a href="#" class="menu-bar">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </a>
                                                        <a href="#" class="menu-times close">
                                                            <span></span>
                                                            <span></span>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                            </div>         
                        </div>
                    </div>
                </div>
            </header>
);

export default Header;