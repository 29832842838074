import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import ArticlePageContent from '../components/ArticlePageContent';
import Sidebar from '../components/Sidebar';
import NotFoundPage from './NotFoundPage';
import { Facebook, Twitter, Pinterest } from 'react-sharingbuttons'
import 'react-sharingbuttons/dist/main.css'



const ArticlePage = ({ match }) => {
    const name = match.params.name;
    //const path = (match.params.year)+'/'+(match.params.month);
    
    const [articles, setArticleInfo] = useState([]);

        const state = {
      loading: true
    };

    useEffect(() => {
        const fetchData = async () => {
             console.log(`https://bharatdaily.in/content/article-content-test.php?name=${name}`);
            const result = await fetch(`https://bharatdaily.in/content/article-content-test.php?name=${name}`);
            const body = await result.json();
            setArticleInfo(body.article);
            const state = {
              loading: false
            };
        }
        fetchData();
    }, [name]);

    if (articles=="") {
      return <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
    }   


    

    return (
        <>
        
                              
         
          <section class="bg-body section-space-less30">
                <div class="container">
                    <div class="row">
                      
                           <ArticlePageContent articles={articles} /> 
                          
                      <Sidebar articles={articles} />
                    </div>
                </div>
          </section>  
                      
                      
                     
       
       
        </>
    );
}

export default ArticlePage;