import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import ArticlesListPage from './pages/ArticlesListPage';
import ArticlesHomePage from './pages/ArticlesHomePage';
import ArticlePage from './pages/ArticlePage';
import NotFoundPage from './pages/NotFoundPage';
import Header from './Header';
import Footer from './Footer';
import './App.css';


class App extends Component {
  render() {
    return (
      <Router>
        <div id="wrapper">
          <Header />        
          <main>
            <div class="container">
                <div class="row">           
              <Switch>
                <Route path="/" component={ArticlesHomePage} exact />
                <Route path="/about" component={AboutPage} />
                <Route path="/category/:name" component={ArticlesListPage} />
                <Route path="/pressreleases/:name" component={ArticlePage} />
                <Route path="/sitemap-gen" render={() => <generateSitemap />} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
            </div> 
          </main>
          <Footer />
       </div>
      </Router>
    );
  }
} 

export default App;
