import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import ArticlesListHome from '../components/ArticlesListHome';


const ArticlesHomePage = ({match}) => {
    
  const name = match.params.name;  
  
  const [articles, setArticleInfo] = useState([]);

  const [pageCount, setPageCount] = useState(1); 
    const [isLoaded, setisLoaded] = useState(false);
    const [currentPage, setcurrentPage] = useState(0);  
  
              
const handlePageChange = (selectedObject) => {
  setcurrentPage(selectedObject.selected);
  handleFetch();
};


 const handleFetch = () => {
  fetch(URL)
    .then(response => response.json())
    .then(body => {
      setArticleInfo(body.article);
          setPageCount(body.npages); 
          setisLoaded(true);
    })
    .catch(error => console.error('Error', error));
};

  useEffect(() => {
      const fetchData = async () => {
          const result = await fetch(`https://bharatdaily.in/content/article-home-test.php?c=${name}&p=${currentPage}`);
          const body = await result.json();
          setArticleInfo(body.article);
          setPageCount(body.npages); 
          setisLoaded(true);
      }
      fetchData();
  }, [name,currentPage]);
  
  if (articles=="") {
    return <div className="spinner-container">
    <div className="loading-spinner">
    </div>
  </div>
  }   
console.log(articles);

    return (
    <>
    <MetaTags>
            <title>{name}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
       
      <ArticlesListHome articles={articles} />   
      
    </>
    );
  };
export default ArticlesHomePage;